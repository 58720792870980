import React, { ReactNode } from 'react';
import { BaseComponentProps } from '@/types';

type Props = {
  children?: ReactNode;
  className?: string;
};

export default function Navigation(props: BaseComponentProps & Props) {
  const { children, className } = props;

  return (
    <nav className={`fixed w-full ${className}`} {...props}>
      {children}
    </nav>
  );
}

Navigation.defaultProps = {
  children: null,
  className: ``,
};
