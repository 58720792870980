import React, { useEffect } from 'react';

declare global {
  interface Window {
    Calendly: any;
  }
}

export default function CalendarBooking() {
  useEffect(() => {
    const script = document.createElement(`script`);
    script.src = `https://assets.calendly.com/assets/external/widget.js`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="overflow-hidden">
      <button
        type="button"
        onClick={() => {
          window.open(
            `https://calendly.com/akanksha-vitract-15/get-to-know-us`,
          );
        }}
        className="w-[107px] text-primary bg-[#FFFFFF] border border-primary px-[16.5px] py-[7.5px] rounded-[4px] text-sm font-medium hover:bg-navy-blue hover:border-navy-blue hover:text-[#FFFFFF] hover:shadow-2xl hover:scale-[1.015] transition-all duration-[0.1s] ease-in-out overflow-hidden"
      >
        Book a Call
      </button>
    </div>
  );
}
