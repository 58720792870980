import React, { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { Mixpanel } from '@/utils';

export default function PricingModalComponent({
  open = false,
  setOpen = (arg) => arg,
  callback = () => null,
}) {
  const cancelButtonRef = useRef(null);
  const mixpanel = Mixpanel;

  const [submitButtonState, setSubmitButtonState] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data: any) => {
    try {
      // return console.log(data);
      setSubmitButtonState(true);
      await mixpanel.setPeople({
        $name: data.name,
        $email: data.email,
        'Mailing Address': data.address,
      });
      await mixpanel.track(`Filled pricing form`);
      setSubmitButtonState(false);
      callback?.();
    } catch (error) {
      setSubmitButtonState(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999999]"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden min-h-[749px] bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[1440px]">
                <div className="flex items-center py-[24px]">
                  <img
                    src="/images/logo.svg"
                    className="mx-auto h-[30px]"
                    alt="Vitract | pricing"
                  />
                </div>
                <div className="grid md:grid-cols-2 h-full">
                  <div
                    className="object-contain h-full md:block bg-cover bg-top col-span-1 hidden md:col-span-1 bg-no-repeat"
                    style={{
                      backgroundImage: `url(/images/pricing-modal-1.png)`,
                    }}
                  />
                  <div className="col-span-2 md:col-span-1 py-[48px] px-4 md:pr-0 md:pl-[30px] bg-[#FBFBFB]">
                    <div className="max-w-[498px]">
                      <h1 className="text-[32px] font-[700] leading-[38.4px] text-[#484352]">
                        Hi, provide your information to continue.
                      </h1>

                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="mt-[47px]"
                      >
                        <div>
                          <label htmlFor="name" className="grid">
                            Name
                            <input
                              id="name"
                              className="border-[1px] mt-[12px] border-black rounded-[4px] text-[16px] pl-[16px] py-[16px]"
                              placeholder="Enter your full name"
                              {...register(`name`, { required: true })}
                            />
                            {errors.name && (
                              <span className="text-red-600 text-sm">
                                This field is required
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="mt-[20px] md:mt-[32px]">
                          <label htmlFor="email" className="grid">
                            Email Address
                            <input
                              id="email"
                              type="email"
                              className="border-[1px] mt-[12px] border-black rounded-[4px] text-[16px] pl-[16px] py-[16px]"
                              placeholder="Enter your email address"
                              {...register(`email`, { required: true })}
                            />
                            {errors.email && (
                              <span className="text-red-600 text-sm">
                                This field is required
                              </span>
                            )}
                          </label>
                        </div>
                        <div className="mt-[20px] md:mt-[32px]">
                          <label htmlFor="address" className="grid">
                            Mailing Address
                            <input
                              id="address"
                              className="border-[1px] mt-[12px] border-black rounded-[4px] text-[16px] pl-[16px] py-[16px]"
                              placeholder="Enter your mailing address"
                              {...register(`address`, { required: true })}
                            />
                          </label>

                          {errors.address && (
                            <span className="text-red-600 text-sm">
                              This field is required
                            </span>
                          )}
                        </div>

                        <button
                          type="submit"
                          disabled={submitButtonState}
                          className={`${
                            !submitButtonState
                              ? `bg-[#FF713F] text-white`
                              : `bg-gray-200 text-gray-700 cursor-pointer`
                          }  mt-[60px] w-full py-[16px] rounded-[4px]`}
                        >
                          Continue
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
